import React from "react";

import styled from "styled-components";




export const IRData = () => {
    
    return (
        <>  
            <div style={{  /* height는 아래 다른 컨텐츠로 채워질경우 지워야한다. */ height: "70vh"}}>
                <h2>해당 페이지는 준비중입니다 ...</h2>
                <h2>This page is under construction...</h2>
            </div>
        </>
    )
}